import React from 'react';

import { buildPlayerUrl } from 'scripts/modules/pbs-player-iframe';

export const ShowRowPreviewPlayer: React.FC<ShowRowPreviewPlayerProps> = (props) => {
  const { title, slug } = props;

  // using the right variation of the player based on whether we're in the SVP or not
  const playerEmbedType = document
    .querySelector(`body`)
    .classList.contains(`svp`)
    ? `stationplayer/`
    : `portalplayer/`;

  // if there is a window object with an embedURL, use that, else, fallback to player prod
  const playerEmbedURL =
  window.PBS?.playerConfig?.embedURL ?
  window.PBS.playerConfig.embedURL :
  `https://player.pbs.org/`;

  const playerSource = buildPlayerUrl({
    autoplay: true,
    embedType: playerEmbedType,
    embedURL: playerEmbedURL,
    slug: slug,
    disableContinuousPlay: true,
  });

  return (
    <div className="show-row-preview-modal__player-container" id={`player-${slug}`}>
      <iframe
        title=
        name="show-row-preview"
        src={playerSource}
        allowFullScreen
        allow="encrypted-media"
        data-testid="rtl-previewModalIframe"
      />
    </div>
  );
}
