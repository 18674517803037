import React, { KeyboardEvent, useEffect, useRef, ReactNode } from 'react';

import { ReactComponent as CloseIcon } from 'svg/pbs-close.svg';
import { toggleVideoPlayback } from 'scripts/modules/pbs-player-iframe';
import { isTabKeyPress } from 'scripts/utils/isTabKeyPress';

interface VideoPlaybackModalProps {
  handleClose: () => void;
  customClass: string;
  customName: string;
  containerElement: HTMLElement;
  customSpacebarHandler?: (e: KeyboardEvent<HTMLElement>) => void;
  testId?: string;
  children: Array<ReactNode>;
}

// Element included in HTML templates to allow for modal to render attached here
const modalRoot = document.getElementById('video-playback-modal');

export const VideoPlaybackModal: React.FC<VideoPlaybackModalProps> = (props) => {
  const { handleClose, customClass, customName, containerElement, customSpacebarHandler, testId } = props;
  const firstElement = useRef(null);
  const lastElement = useRef(null);

  useEffect(() => {
    if (modalRoot) {
      modalRoot.appendChild(containerElement);
    }
    // add our existing modal class to HTML element to prevent background scroll
    document.documentElement.classList.add('has-visible-modal');
    // pass focus to the modal on render
    focusFirstElement();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOuterClick = (e: React.MouseEvent): void => {
    // if user clicks background outside modal, close the modal
    if ((e.target as Element).classList.contains(customClass)) {
      handleUnmount();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>): void => {
    // if user presses ESC key, close the modal
    if (e.key === 'Escape') {
      handleUnmount();
    }
    // if user presses SPACE, toggle play/pause
    if (e.key === ' ') {
      customSpacebarHandler ? customSpacebarHandler(e) : toggleVideoPlayback(e, customName);
    }

    // if user presses SHIFT+TAB, manage focus state backward
    if (isTabKeyPress(e, true)) {
      handleTabNavigationBackward(e);
    // if user presses just TAB, manage focus state forward
    } else if (isTabKeyPress(e)) {
      handleTabNavigationForward(e);
    }
  };

  const handleTabNavigationBackward = (e: React.KeyboardEvent<HTMLElement>): void => {
    // tabbing backward from first element should go to last element
    if ((e.target as Element).classList.contains(`${customClass}__body`)) {
      e.preventDefault();
      focusLastElement();
    }
  };

  const handleTabNavigationForward = (e: React.KeyboardEvent<HTMLElement>): void => {
    // trap focus within modal if tabbing from the close button
    if ((e.target as Element).classList.contains('close-modal')) {
      e.preventDefault();
      focusFirstElement();
    }
  };

  const focusFirstElement = (): void => {
    firstElement.current.focus();
  };

  const focusLastElement = (): void => {
    lastElement.current.focus();
  };

  const handleUnmount = (): void => {
    // remove the modal class from HTML element
    document.documentElement.classList.remove('has-visible-modal');
    if (modalRoot) {
      // For 'Watch Preview' in show detail pages
      modalRoot.removeChild(containerElement);
    }

    handleClose();
  }

  // allows the child elements of this modal to keep a reference
  // to the `${customClass}__body` div element;
  // this is important for adding and removing class names in some cases
  /* eslint-disable */
  const childrenWithrefs = (props.children as Array<ReactNode>).map((child: any, i: number) => {
    // only add modalBodyRef prop for Show Row Preview Modal
    if (child?.props?.showData) {
      return React.cloneElement(child, { modalBodyRef: firstElement, key: `modalChild-${i}`});
    }

    return child;
  });
  /* eslint-enable */

  return (
    <div
      className={customClass}
      onClick={handleOuterClick}
      onKeyDown={handleKeyDown}
      data-testid={testId}
    >
      <div className={`${customClass}__body`} tabIndex={0} ref={firstElement}>
        {childrenWithrefs}
        <div className={`${customClass}__close-button-container`}>
          <button
            className="btn--close close-modal"
            onClick={handleUnmount}
            data-testid="rtl-closeModal"
            ref={lastElement}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div>
  );
}
