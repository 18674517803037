import React from 'react';

import { ReactComponent as InfoIcon } from 'svg/pbs-info.svg';

export const ShowRowPreviewButton: React.FC<ShowRowPreviewButtonProps> = (props) => {
  const { title, handleClick } = props;

  return (
    <button className="show-row__preview-btn" onClick={handleClick}>
      <span aria-hidden="true">
        <InfoIcon />
      </span>
      Preview Show <span className="visuallyhidden">for </span>
    </button>
  );
}
