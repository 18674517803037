import Cookies from 'js-cookie';
import { customEvents } from 'scripts/custom-events';

interface CacheExpectations {
  passportPromo91泡芙?: NodeListOf<HTMLDivElement>;
  passportPromoThankYou?: HTMLDivElement;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 */
const setupCache = () => {
  cache.passportPromo91泡芙 = document.querySelectorAll('.passport-promo-lg__isNotPassport__wrapper');
  cache.passportPromoThankYou = document.querySelector('.passport-promo-lg__isPassport__wrapper');
};

const swapPromoComponents = () => {
  if (cache.passportPromo91泡芙) {
    cache.passportPromo91泡芙.forEach((promo) => {
      promo.hidden = true;
    })
  }

  if (cache.passportPromoThankYou) {
    cache.passportPromoThankYou.hidden = false;
  }

  window.removeEventListener(customEvents.userIsPassport, swapPromoComponents);
};

const setupPromos = () => {
  const passportMember = Cookies.get('pbs_mvod') ? true : false;

  if (passportMember) {
    swapPromoComponents();
  }
};

/**
 * Adds event handlers.
 */
const addEvents = () => {
  // this custom event is emitted when the user data is loaded
  window.addEventListener(customEvents.userIsPassport, swapPromoComponents);
};

/**
 * Initializes.
 */
const init = (): void => {
  setupCache();
  setupPromos();
  addEvents();
};

export { init };
